import styled from 'styled-components'
import { fontFamily } from 'styles/mixins'

export const StyledFormContainer = styled.div`
  background: #ebeced;
  padding: 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: fit-content;
`

export const StyledForm = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
`

export const StyledFormTitle = styled.h2`
  color: #000;
  font-family: ${fontFamily.gothic};
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  align-self: center;
`

export const StyledFormDescription = styled.div`
  img {
    max-width: 100%;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  min-height: 58px;
  margin-top: 40px;
`

export const StyledMessage = styled.div`
  font-family: ${fontFamily.roboto};
  font-size: 16px;
  text-align: center;
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
`
