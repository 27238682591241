import Footer from 'components/Footer'
import Header from 'components/Header'
import GridHelper from 'components/helper/GridHelper'
import Login from 'components/Login'
import ModalKnowledge from 'components/Modal/ModalKnowledge'
import Overlay from 'components/Overlay'
import Tracking from 'components/Tracking'
import Cookies from 'js-cookie'
import assignIn from 'lodash/assignIn'
import find from 'lodash/find'
import get from 'lodash/get'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import replace from 'lodash/replace'
import { useEffect } from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { useStore } from 'store'
import { ThemeProvider } from 'styled-components'

import { RECAPTCHA_V3_SITE_KEY, SHOW_DEV_GRID } from '../config/app.config'

const LayoutContainer = (props) => {
  const pageComponent = get(props, 'pageComponent')
  const { menuState, themeState, trackingState, headerState, loginState, userState } = useStore()

  const path = typeof window === 'undefined' ? props.req.url : window.location.pathname
  const menuDispatch = menuState.dispatch
  const themeDispatch = themeState.dispatch
  const sections = menuState.state.sections
  const sectionEntityUuid = get(
    find(sections, (section) => includes(section.path, replace(path, /^\/yp/, ''))),
    'entityUuid',
  )
  const query = get(props, 'query', {})

  useEffect(() => {
    if (sectionEntityUuid) {
      menuDispatch({ type: 'UpdateSection', payload: { entityUuid: sectionEntityUuid } })
    }
    if (sectionEntityUuid)
      themeDispatch({ action: 'UpdateTheme', payload: { entityUuid: sectionEntityUuid } })
  }, [menuDispatch, themeDispatch, sectionEntityUuid])

  const menuStateValue = get(menuState, 'state', {})

  const isParentSection =
    get(menuStateValue, 'entityUuid') === get(menuStateValue, 'activeSection.depth1.entityUuid') &&
    get(props, 'routerType') !== 'article'
  const isArticle = get(props, 'routerType') === 'article'
  let scrollableWrapperMode =
    get(headerState, 'state.scrollableWrapperMode') || isParentSection || isArticle

  if (get(menuStateValue, 'hideMe')) scrollableWrapperMode = true

  const activeTheme = assignIn(get(themeState, 'state.activeTheme'), {
    routerType: props.routerType,
    pageComponent,
  })
  const switchTheme = (entityUuid) => {
    themeState.dispatch({ action: 'UpdateTheme', payload: { entityUuid } })
  }
  const initTracking = () => {
    trackingState.dispatch({ action: 'initGtm' })
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(initTracking, [])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const userCookie = JSON.parse(Cookies.get('scmp_user') || '{}')
      userState.dispatch({ action: 'UpdateUserInfo', payload: { userInfo: userCookie } })
      if (!isEmpty(userCookie)) {
        loginState.dispatch({ action: 'UpdateloginedState', payload: { loginedState: true } })
      } else {
        loginState.dispatch({ action: 'UpdateloginedState', payload: { loginedState: false } })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ThemeProvider theme={activeTheme}>
      {loginState && loginState.state.loginPopupState && <Login />}
      <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_V3_SITE_KEY}>
        <Overlay />
      </GoogleReCaptchaProvider>
      <ModalKnowledge />
      <Tracking query={query} />
      {SHOW_DEV_GRID && <GridHelper isShow={false}></GridHelper>}
      {!scrollableWrapperMode && <Header switchTheme={switchTheme} pageComponent={pageComponent} />}
      {props.children}
      {!scrollableWrapperMode && <Footer />}
    </ThemeProvider>
  )
}

export default LayoutContainer
