import FormLabel  from './FormLabel'
import { StyledInput, StyledError} from './styles'

const TextInput = ({
  defaultValue,
  placeholder,
  label,
  required,
  name,
  register,
  error,
  type = 'text',
  helperText
}) => (
  <div> 
    <FormLabel label={label} required={required} helperText={helperText} /> 
    <StyledInput
      type={type}
      error={error}
      {...register(name)}
      defaultValue={defaultValue}
      placeholder={placeholder}
    />
    {error && <StyledError>{error.message}</StyledError>}
  </div>
)

export default TextInput
