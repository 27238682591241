import OSS from 'ali-oss'
import { STUDENT_OSS_BUCKET, STUDENT_OSS_REGION } from 'config/app.config'
import { useEffect, useRef, useState } from 'react'

import { useOssCredential } from '../FormContainer/context'

import FormLabel from './FormLabel'
import {
  StyledFileDisplayCancelButton,
  StyledFileDisplayInputWrapper,
  StyledInput,
  StyledError,
} from './styles'

const FileInput = ({
  label,
  required,
  name,
  register,
  error,
  accept,
  setValue,
  setError,
  formId,
  helperText,
}) => {
  const ossClient = useRef(null)
  const { accessKeyId, accessKeySecret, stsToken } = useOssCredential()
  const [uploadedFile, setUploadedFile] = useState(null)
  const [fileName, setFileName] = useState('')

  useEffect(() => {
    if (!accessKeyId || !accessKeySecret || !stsToken) return
    ossClient.current = new OSS({
      accessKeyId,
      accessKeySecret,
      stsToken,
      bucket: STUDENT_OSS_BUCKET,
      region: STUDENT_OSS_REGION,
    })
  }, [accessKeyId, accessKeySecret, stsToken])

  async function uploadFile(file) {
    if (!file) return
    if (!ossClient.current) {
      throw new Error('OSS client not initialized')
    }

    setError(name, null)

    try {
      if (!file.type.startsWith('image/')) {
        setError(name, { message: 'Please upload an image file' })
        setValue(name, '')
        return
      }

      if (file.size > 10 * 1024 * 1024) {
        setError(name, { message: 'File too large, max size is 10MB' })
        setValue(name, '')
        return
      }

      const uploadFileName = `tmp/${formId}/${file.name}`
      await ossClient.current.put(uploadFileName, file)
      setValue(name, uploadFileName)
      setUploadedFile(uploadFileName)
      setFileName(file.name)
      setError(name, null)
    } catch (err) {
      setError(name, { message: err.message || 'Failed to upload' })
      setValue(name, '')
    }
  }

  const handleCancel = () => {
    setValue(name, '')
    setUploadedFile('')
    setFileName('')
    setError(name, null)
  }

  return (
    <div>
      <FormLabel label={label} required={required} helperText={helperText} />
      {uploadedFile ? (
        <StyledFileDisplayInputWrapper>
          <StyledInput type="text" value={fileName} disabled />
          <StyledFileDisplayCancelButton onClick={handleCancel}>×</StyledFileDisplayCancelButton>
          <input type="hidden" value={uploadedFile} {...register(name)} />
        </StyledFileDisplayInputWrapper>
      ) : (
        <StyledInput type="file" accept={accept} onChange={(e) => uploadFile(e.target.files[0])} />
      )}
      {error && <StyledError>{error.message}</StyledError>}
    </div>
  )
}

export default FileInput
