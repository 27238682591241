import gql from 'graphql-tag'

const FIELD_ATTRIBUTES = gql`
  fragment FieldAttributes on FieldConfig {
    title
    display
    required
    placeholder
    helperText
  }
`

export const FORM_FIELDS = gql`
  fragment FormFields on Form {
    documentId
    isExpired
    title
    description {
      json
      html
    }
    email {
      ...FieldAttributes
    }
    name {
      ...FieldAttributes
    }
    dateOfBirth {
      ...FieldAttributes
    }
    school {
      ...FieldAttributes
    }
    phone {
      ...FieldAttributes
    }
    school {
      ...FieldAttributes
    }
    education: yearOrGrade {
      ...FieldAttributes
    }
    address {
      ...FieldAttributes
    }
    facebook {
      ...FieldAttributes
    }
    instagram {
      ...FieldAttributes
    }
    photo {
      ...FieldAttributes
    }
    artwork {
      ...FieldAttributes
    }
    questions {
      documentId
      order
      required
      question {
        json
        html
      }
      answerType
      wordLimit
      maxSelect
      options {
        value
      }
    }
    educationOptions: educations {
      documentId
      name
      isOther
    }
  }
  ${FIELD_ATTRIBUTES}
`

export const GET_FORM_BY_ID = gql`
  query getFormById($documentId: ID!) {
    getForm(documentId: $documentId) {
      ...FormFields
    }
  }
  ${FORM_FIELDS}
`

export const GET_FORM_BY_TYPE = gql`
  query getFormByType($typeId: ID!) {
    getLatestFormByType(typeId: $typeId) {
      ...FormFields
    }
  }
  ${FORM_FIELDS}
`

export const GET_SCHOOLS = gql`
  query getSchools($keyword: String) {
    getSchools(filters: { keyword: $keyword }) {
      documentId
      name
    }
  }
`
