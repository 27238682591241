import { useQuery, useMutation } from '@apollo/client'
import { CREATE_FORM_SUBMISSION } from 'apollo/mutations/student-service'
import { GET_FORM_BY_ID, GET_FORM_BY_TYPE, GET_SCHOOLS } from 'apollo/queries/student-service'
import get from 'lodash/get'

export const useFetchStudentForm = ({ formId, queryType, ssr = false }) => {
  const { data, loading } = useQuery(queryType === 'latest' ? GET_FORM_BY_TYPE : GET_FORM_BY_ID, {
    context: { clientName: 'student-service' },
    variables: queryType === 'latest' ? { typeId: formId } : { documentId: formId },
    notifyOnNetworkStatusChange: true,
    ssr,
  })
  return {
    data: get(data, queryType === 'latest' ? 'getLatestFormByType' : 'getForm', {}),
    loading,
  }
}

export const useCreateStudentFormSubmission = () => {
  const [createFormSubmission] = useMutation(CREATE_FORM_SUBMISSION, {
    context: { clientName: 'student-service' },
  })

  const submit = async (variables) => {
    try {
      return await createFormSubmission({ variables })
    } catch (error) {
      console.error('Error creating form submission:', error)
      throw error
    }
  }

  return submit
}

export const useFetchSchools = ({ variables, skip }) => {
  return useQuery(GET_SCHOOLS, {
    context: { clientName: 'student-service' },
    variables,
    skip,
  })
}
