import SvgInfo from 'public/assets/svg/icon-info.svg'
import { useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'

import { StyledLabel, Tooltips, TooltipsContent, IconInfoContainer, StyledTooltipsInfoArrow } from './styles'

const FormLabel = ({
  label,
  required,
  helperText
}) => {
  const [openTooltip, setOpenTooltip] = useState(false)
  const [isClick, setIsClick] = useState(false)

  return (
    <OutsideClickHandler
      useCapture={false}
      onOutsideClick={() => {
        setOpenTooltip(false)
        setIsClick(false)
      }}
    >
      <StyledLabel>
        <span>{label}{required && '*'}</span>
        {helperText && (
          <IconInfoContainer 
            onClick={() => {
              setOpenTooltip(true)
              setIsClick(true)
            }}
            onMouseLeave={() => {
              !isClick && setOpenTooltip(false)
            }}
            onMouseOver={() => {
              setOpenTooltip(true)
            }}
          >
            {openTooltip && <StyledTooltipsInfoArrow />}
            <SvgInfo />
          </IconInfoContainer>
        )}
        {
          openTooltip && (
            <Tooltips>
              <TooltipsContent>
                {helperText}
              </TooltipsContent>
            </Tooltips>
          )}
      </StyledLabel>
    </OutsideClickHandler>
)}

export default FormLabel
 