import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { ApolloProvider } from '@apollo/client'
import * as scmpAccount from '@product/scmp-account-sdk'
import withApolloClient from 'apollo/client/with-apollo-client'
// import { StoreProvider } from 'store'
import Meta from 'components/Common/Meta'
import Layout from 'components/LayoutContainer'
import Rosetta from 'components/Rosetta'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import App from 'next/app'
import Router from 'next/router'
import { StoreProvider, injectStates } from 'store'
import { ThemeProvider } from 'styled-components'
import GlobalStyle from 'styles/global'
import JsxGlobalStyle from 'styles/global/styled-jsx'
import theme from 'styles/theme'
import { checkHashForStudentForm } from 'utils/common'

import {
  CENTRALIZE_LOGIN_PROTOCOL,
  CENTRALIZE_LOGIN_HOST,
  ACCOUNT_DEFAULT_AUTH_CALLBACK_URL,
  ACCOUNT_DEFAULT_REGISTER_CALLBACK_URL,
  RECAPTCHA_SITE_KEY,
  ACCOUNT_MIXPANEL_TOKEN,
} from '../config/app.config'

class YoungPostApp extends App {
  // Only uncomment this method if you have blocking data requirements for
  // every single page in your application. This disables the ability to
  // perform automatic static optimization, causing every page in your app to
  // be server-side rendered.
  //

  static async getInitialProps(appContext) {
    const {
      ctx: { req },
      ctx: { query },
    } = appContext
    // calls page's `getInitialProps` and fills `appProps.pageProps`
    const appProps = await App.getInitialProps(appContext)

    appProps.pageProps = {
      ...appProps.pageProps,
      routerType: appProps?.pageProps?.type ?? get(query, 'type', 'static'),
    }
    if (typeof window === 'undefined') {
      appProps.pageProps.req = {
        url: req.originalUrl,
      }
      appProps.pageProps.storeInjection = injectStates(appContext)
    }

    return { ...appProps }
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      if (window.location.hash === '#comments') {
        window.c3po = {
          show: true,
        }
      }
      const studentFormParams = checkHashForStudentForm(window.location.hash)
      if (studentFormParams?.isOpenStudentFormLink) {
        window.studentFormParams = studentFormParams
      }
      // init for account SDK
      scmpAccount.init({
        SCMP_ACCOUNT_HOST: `${CENTRALIZE_LOGIN_HOST}`,
        SCMP_ACCOUNT_PROTOCOL: `${CENTRALIZE_LOGIN_PROTOCOL}`,
        AUTH_CALLBACK_URL: `${ACCOUNT_DEFAULT_AUTH_CALLBACK_URL}`,
        REGISTER_CALLBACK_URL: `${ACCOUNT_DEFAULT_REGISTER_CALLBACK_URL}`,
        DESTINATION: `${window.location.href}`,
        RECAPTCHA_SITE_KEY: `${RECAPTCHA_SITE_KEY}`,
        RECAPTCHA_CONTAINER_ID: '',
        MIXPANEL_TOKEN: `${ACCOUNT_MIXPANEL_TOKEN}`,
        DEBUG_LOG: false,
        APPLICATION: scmpAccount.APPLICATION.YP,
      })

      // validate token, refresh token if needed
      scmpAccount.validateToken()
    }
    // Page scroll to the top after route changed
    Router.events.on('routeChangeComplete', () => {
      const tp = window.tp || []
      if (!isEmpty(tp)) {
        const elBody = document.querySelector('body')
        if (elBody) {
          if (elBody.classList.contains('offer-meter-open')) {
            tp.offer.close()
            elBody.classList.remove('offer-meter-open')
          }
        }
      }
      window.scrollTo(0, 0)

      const articleWrapper = document.getElementById('article-scroll-wrapper')
      if (articleWrapper) {
        articleWrapper.scroll(0, 0)
      }
    })
  }

  isPrintRoute(url) {
    return url && url.includes('/print/')
  }

  render() {
    const { apolloClient, pageProps, Component } = this.props
    return this.isPrintRoute(get(pageProps, 'req.url')) ? (
      <>
        <StoreProvider injection={get(pageProps, 'storeInjection', {})}>
          <ApolloProvider client={apolloClient}>
            <Rosetta />
            <Component {...pageProps} />
          </ApolloProvider>
        </StoreProvider>
        <style jsx global>
          {JsxGlobalStyle}
        </style>
      </>
    ) : (
      <>
        <GlobalStyle />
        <ThemeProvider theme={theme}>
          <StoreProvider injection={get(pageProps, 'storeInjection', {})}>
            <ApolloProvider client={apolloClient}>
              <Rosetta />
              <Meta />
              <Layout pageComponent={get(this, 'props.Component.name')} {...pageProps}>
                <Component {...pageProps} />
              </Layout>
            </ApolloProvider>
          </StoreProvider>
        </ThemeProvider>
        <style jsx global>
          {JsxGlobalStyle}
        </style>
      </>
    )
  }
}

export default withApolloClient(YoungPostApp)
