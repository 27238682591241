import { useState } from 'react'

import {
  StyledInput,
  StyledLabel,
  StyledError,
  StyledSchoolInputWrapper,
  StyledDropdownList,
  StyledDropdownItem,
} from './styles'

const EducationInput = ({ register, label, placeholder, required, error, educationOptions }) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const [selectedEducation, setSelectedEducation] = useState(null)
  const [otherValue, setOtherValue] = useState('')

  return (
    <div>
      <StyledLabel>
        {label} {required && '*'}
      </StyledLabel>
      <StyledSchoolInputWrapper>
        <StyledInput
          type="text"
          error={error}
          placeholder={placeholder}
          value={selectedEducation?.name || ''}
          onChange={() => {}}
          onClick={() => setShowDropdown(true)}
        />

        {showDropdown && (
          <StyledDropdownList>
            {educationOptions?.map((education) => (
              <StyledDropdownItem
                key={education.documentId}
                onClick={() => {
                  setSelectedEducation(education)
                  if (!education.isOther) {
                    register('education').onChange({
                      target: {
                        name: 'education',
                        value: {
                          documentId: education.documentId,
                          value: education.name,
                        },
                      },
                    })
                  } else {
                    setOtherValue('')
                    register('education').onChange({
                      target: {
                        name: 'education',
                        value: {
                          documentId: education.documentId,
                          value: '',
                        },
                      },
                    })
                  }
                  setShowDropdown(false)
                }}
              >
                {education.name}
              </StyledDropdownItem>
            ))}
          </StyledDropdownList>
        )}
      </StyledSchoolInputWrapper>

      {selectedEducation?.isOther && (
        <div style={{ marginTop: '10px' }}>
          <StyledInput
            {...register('education.value', {
              required: 'Please enter your year/grade',
            })}
            type="text"
            error={error}
            placeholder="Enter your year/grade"
            value={otherValue}
            onChange={(e) => {
              setOtherValue(e.target.value)
              register('education').onChange({
                target: {
                  name: 'education',
                  value: {
                    documentId: selectedEducation.documentId,
                    value: e.target.value,
                  },
                },
              })
            }}
          />
        </div>
      )}

      {error && <StyledError>{error?.value?.message || error?.message}</StyledError>}
    </div>
  )
}

export default EducationInput
