import { useState } from 'react'

import { wordCount } from '../FormContainer/schema'

import { StyledTextArea, StyledError, WordCount } from './styles'

const TextArea = ({ name, register, error, setError, placeholder, wordLimit }) => {
  const [wordCountVal, setWordCount] = useState(0)

  const handleChange = (e) => {
    const count = wordCount(e.target.value)
    setWordCount(count)

    if (wordLimit) {
      if (count <= wordLimit) {
        setError(name, undefined)
      } else {
        setError(name, {
          type: 'wordLimit',
          message: `Please limit your answer to ${wordLimit} words`,
        })
      }
    }
  }

  return (
    <>
      <StyledTextArea
        error={error?.type === 'wordLimit'}
        {...register(name, {
          onChange: handleChange,
        })}
        placeholder={placeholder}
      />
      {error && <StyledError>{error.message}</StyledError>}
      {wordLimit && wordCountVal > 0 && (
        <WordCount error={wordCountVal > wordLimit}>
          Remaining: {wordLimit - wordCountVal}
        </WordCount>
      )}
    </>
  )
}

export default TextArea
