import Button from 'components/Button'
import styled from 'styled-components'

export const StyledButtonOuter = styled.div`
  width: fit-content;
  background: #000000;
  display: inline-block;
  border: 0.188rem solid #000000;
  border-radius: 0.5rem;
  background-color: #ffffff;
`

export const StyledButton = styled(Button)`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
`
