import Button from 'components/StudentForm/Button'
import FormContainer from 'components/StudentForm/FormContainer'
import { useFetchStudentForm } from 'hooks/hook-fetch-student-form'
import get from 'lodash/get'
import CloseIcon from 'public/assets/svg/icon-close.svg'
import WarningIcon from 'public/assets/svg/icon-warning.svg'
import { useState } from 'react'
import { useStore } from 'store'
import styled, { css } from 'styled-components'
import { fontFamily, remCalc, respondTo } from 'styles/mixins'

const StyledOverlay = styled.div`
  width: ${remCalc(485)};
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
  overflow-x: hidden;
  overflow-y: auto;
  transition: all 0.3s ease-in-out;
  background-color: #ebeced;
  box-shadow:
    0 9px 12px 1px rgba(0, 0, 0, 0.14),
    0 3px 16px 2px rgba(0, 0, 0, 0.12),
    0 5px 6px 0 rgba(0, 0, 0, 0.2);
  transform: translateX(calc(100% + 20px));

  ${(props) =>
    props.isVisible &&
    css`
      transform: translateX(0);
    `}

  ${respondTo.smAndDown`
    width: 100%;
  `}
`

const CloseButton = styled(CloseIcon)`
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
`

const WarningOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
`

const CloseWarningContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10001;

  display: flex;
  flex-direction: column;
  gap: 24px;

  background: #ebeced;
  width: 350px;
  padding: 55.6px 160px 55px 160px;
  justify-content: center;
  align-items: center;
`

const CloseLink = styled.span`
  color: #000;
  font-family: ${fontFamily.roboto};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  border-bottom: 1px solid currentColor;
  text-decoration: none;
  display: inline-block;
  line-height: 1;
  cursor: pointer;
`

const OverlayForm = ({ className }) => {
  const { headerState, overlayState } = useStore()
  const isShown = get(overlayState, 'state.show', false)
  const [showWarning, setShowWarning] = useState(false)
  const dismissible = get(overlayState, 'state.dismissible', false)
  const { studentFormQueryType, studentFormId } = get(
    overlayState,
    'state.options.studentFormParams',
  )
  const articleId = get(headerState, 'state.headerEntity.entityId')

  const closeOverlay = () => {
    setShowWarning(false)
    overlayState.dispatch({ type: 'toogleOverlay' })
  }

  const handleClose = () => {
    setShowWarning(true)
  }

  const { data: studentForm, loading } = useFetchStudentForm({
    formId: studentFormId,
    queryType: studentFormQueryType,
  })

  const formId = !loading && !studentForm ? 'invalid' : studentForm?.documentId

  return (
    <>
      {isShown && (
        <>
          {showWarning && (
            <>
              <WarningOverlay />
              <CloseWarningContainer>
                <WarningIcon />
                Are you sure you want to close this form? If you leave without submitting, your work
                will be lost.
                <Button
                  label="Back to Form"
                  width="197"
                  height="71"
                  className="black large"
                  onClick={() => setShowWarning(false)}
                />
                <CloseLink onClick={closeOverlay}>Yes, close it anyway.</CloseLink>
              </CloseWarningContainer>
            </>
          )}
          <StyledOverlay className={className} isVisible={isShown}>
            {!dismissible && <CloseButton onClick={handleClose} />}
            <FormContainer
              formId={formId}
              articleId={articleId}
              formData={studentForm}
              onDone={closeOverlay}
            />
          </StyledOverlay>
        </>
      )}
    </>
  )
}

export default OverlayForm
