import get from 'lodash/get'
import { useStore } from 'store'
import styled from 'styled-components'

import OverlayComment from './OverlayComment'
import OverlayForm from './OverlayForm'
const StyledOverlayDimLayer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9998;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
`

const Overlay = ({ className }) => {
  const { overlayState } = useStore()
  const isShown = get(overlayState, 'state.show', false)
  const overlayType = get(overlayState, 'state.options.overlayType', '')

  const toggleOverlay = () => {
    if (overlayState) {
      if (overlayType === 'form-popup') return
      overlayState.dispatch({ type: 'toogleOverlay' })
    }
  }

  return (
    <div className={className}>
      {isShown && <StyledOverlayDimLayer onClick={toggleOverlay} />}
      {overlayType === 'comment-popup' && <OverlayComment />}
      {overlayType === 'form-popup' && <OverlayForm />}
    </div>
  )
}

export default Overlay
