import { yupResolver } from '@hookform/resolvers/yup'
import { useCreateStudentFormSubmission } from 'hooks/hook-fetch-student-form'
import { useEffect, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useForm } from 'react-hook-form'
import { useStore } from 'store'

import Button from '../Button'
import Question from '../Question'
import FormError from '../SubmitResult/FormError'
import ThankYou from '../SubmitResult/ThankYou'
import UserDetails from '../UserDetails'

import { OssCredentialProvider, getStsToken } from './context'
import Loading from './Loading'
import { createFormSchema } from './schema'
import {
  StyledFormContainer,
  StyledFormTitle,
  ButtonWrapper,
  StyledForm,
  StyledFormDescription,
} from './styles'

const FormContainer = ({ formId, articleId, formData, className, onDone }) => {
  const {
    userState,
    overlayState: { dispatch: overlayDispatch },
  } = useStore()
  const [showThankYouPage, setShowThankYouPage] = useState(false)
  const [showErrorPage, setShowErrorPage] = useState(false)
  const [ossCredential, setOssCredential] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const createSubmission = useCreateStudentFormSubmission()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const userInfo = userState.state.userInfo

  const {
    email,
    name,
    dateOfBirth,
    school,
    education,
    phone,
    questions,
    address,
    facebook,
    instagram,
    photo,
    artwork,
  } = formData || {}

  const schema = createFormSchema({
    email,
    name,
    dateOfBirth,
    school,
    education,
    phone,
    questions,
    address,
    facebook,
    instagram,
    photo,
    artwork,
  })

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  })

  const hasUploadField = photo?.display || artwork?.display

  useEffect(() => {
    // only get OSS credential if there is a photo or artwork and no existing credential
    if (hasUploadField && !Object.keys(ossCredential).length) {
      const getToken = async () => {
        if (!executeRecaptcha) return
        try {
          const recaptchaToken = await executeRecaptcha('form_action')
          if (recaptchaToken) {
            const credential = await getStsToken(recaptchaToken)
            setOssCredential(credential)
          }
        } catch (error) {
          console.error('Error executing recaptcha:', error)
        }
      }
      getToken()
    }
  }, [hasUploadField, executeRecaptcha, ossCredential])

  const setFormDismissible = (dismissible) => {
    overlayDispatch({
      type: 'setOverlayDismissible',
      dismissible,
    })
  }

  const onSubmit = async (data) => {
    try {
      if(!isSubmitting) {
        setShowErrorPage(false)
        setIsSubmitting(true)
  
        const { formId: _, ...formFields } = data
        const answerFields = questions?.map((q) => q.documentId) || []
        const submissionFields = Object.fromEntries(
          Object.entries(formFields).filter(([key]) => !answerFields.includes(key)),
        )
  
        const recaptchaToken = await executeRecaptcha('form_submit')
        const submissionData = {
          input: {
            formId,
            articleEntityId: articleId,
            userUuid: userInfo?.uuid,
            ...submissionFields,
            school: data.school?.documentId || '',
            otherSchool: !data.school?.documentId ? data.school?.value ?? '' : '',
            education: data.education?.documentId || '',
            otherEducation: !data.education?.documentId ? data.education?.value ?? '' : '',
            recaptchaToken,
            answers: questions
              ?.map((q) => {
                if (q.answerType === 'multiple_choice') {
                  return data[q.documentId]?.map((answer) => ({
                    questionId: q.documentId,
                    answer,
                  }))
                }
                return {
                  questionId: q.documentId,
                  answer: data[q.documentId]?.toString() || '',
                }
              })
              .flat()
              .filter((answer) => answer.answer !== ''),
          },
        }
  
        // remove empty values
        submissionData.input = Object.fromEntries(
          Object.entries(submissionData.input).filter(([_, value]) => Boolean(value)),
        )
  
        const response = await createSubmission(submissionData)
  
        setIsSubmitting(false)
        if (!response?.data?.createSubmission?.success) {
          throw new Error(response?.data?.createSubmission?.message || 'Submission failed')
        }

        setShowThankYouPage(true)
      }
    } catch (error) {
      console.error('Error submitting form:', error.message || error)
      setShowErrorPage(true)
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    if (formId === 'invalid' || (formData && formData.isExpired) || showThankYouPage) {
      setFormDismissible(true)
    } else {
      setFormDismissible(false)
    }
  }, [formId, formData?.isExpired, showThankYouPage, showErrorPage])

  if (formId === 'invalid') {
    return <FormError errorType="invalid" onBack={onDone} messageWidth="265" />
  }

  if (!Object.keys(formData).length) {
    return <Loading />
  }

  if (formData.isExpired) {
    return <FormError errorType="expired" onBack={onDone} />
  }

  if (showThankYouPage) {
    return <ThankYou onDone={onDone} />
  }

  if (showErrorPage) {
    return <FormError onBack={() => setShowErrorPage(false)} />
  }

  return (
    <OssCredentialProvider value={ossCredential}>
      <StyledFormContainer className={className}>
        <StyledFormTitle>{formData.title}</StyledFormTitle>
        <StyledFormDescription
          dangerouslySetInnerHTML={{ __html: formData?.description?.html ?? '' }}
        />
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <UserDetails
            userInfo={userInfo}
            formData={formData}
            register={register}
            errors={errors}
            setValue={setValue}
            setError={setError}
          />
          {formData.questions &&
            formData.questions.map((q) => (
              <Question
                key={q.documentId}
                question={q}
                register={register}
                errors={errors}
                setError={setError}
              />
            ))}
          <ButtonWrapper>
            <Button type="submit" label="Submit" width="263" height="58" className="black large" />
          </ButtonWrapper>
        </StyledForm>
      </StyledFormContainer>
    </OssCredentialProvider>
  )
}

export default FormContainer
