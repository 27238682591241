import { CONTENT_API_KEY, STUDENT_STS_TOKEN_SERVICE_API_URI } from 'config/app.config'
import { createContext, useContext } from 'react'

const OssCredentialContext = createContext(null)

export async function getStsToken(recaptchaToken) {
  const res = await fetch(STUDENT_STS_TOKEN_SERVICE_API_URI, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      apiKey: CONTENT_API_KEY,
    },
    body: JSON.stringify({ recaptchaToken }),
  })
  const {
    credentials: { accessKeyId, accessKeySecret, stsToken },
  } = await res.json()
  if (!accessKeyId || !accessKeySecret || !stsToken) {
    throw new Error('Failed to get OSS credential')
  }
  return {
    accessKeyId,
    accessKeySecret,
    stsToken,
  }
}

export const OssCredentialProvider = ({ children, value }) => {
  return <OssCredentialContext.Provider value={value}>{children}</OssCredentialContext.Provider>
}

export const useOssCredential = () => {
  const context = useContext(OssCredentialContext)
  if (context === undefined) {
    throw new Error('useOssCredential must be used within an OssCredentialProvider')
  }
  return context
}
