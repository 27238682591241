import styled from 'styled-components'
import { fontFamily } from 'styles/mixins'

export const StyledSubmitResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  text-align: center;
  height: 100vh;
  box-sizing: border-box;
`

export const StyledTitle = styled.h2`
  color: #000;
  text-align: center;
  font-family: ${fontFamily.gothic};
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 20px;
`

export const StyledMessage = styled.p`
  color: #000;
  text-align: center;
  font-family: ${fontFamily.roboto};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  max-width: ${({ messageWidth }) => messageWidth}px;
  margin-bottom: 32px;
`
