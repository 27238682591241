import { color } from 'styles/mixins'

import { VIDEO_PAGE, SCMP_MAIN_SITE_DOMAIN } from './app.config'

const sectionUuids = {
  spark: '4b44676e-f201-4230-97cc-8dab5c5ecd58',
}

const sections = [
  {
    entityId: '503648',
    name: 'discover',
    path: '/discover',
    bgColor: color.sunshineyellow,
    bgColorTrans: color.sunshineyellow90,
    bgColorZero: color.sunshineyellow0,
    menuBgColor: color.yellowStraw,
    menuHighlightColor: color.yellowPear,
    LogoGradientFrom: color.sulu,
    LogoGradientTo: color.laserLemon,
    highlightColor: color.avocado,
    sponsorTagColor: color.mint,
    highlightTransColor: color.avocado0,
    highlightBgColor: color.sunshineyellow,
    highlightOppositeColor: color.sunshineyellow,
    highlightArticle: color.avocadodaytwo,
    articleShareBg: color.avocado,
    articleCorrectionBg: '#FEFFDA',
    articleCorrectionHighlight: '#97B100',
    transparentEffect: color.gray30,
    entityUuid: 'd41a358a-51b2-406f-b9e7-aadb6fb2d13c',
    depth: 1,
    speechifyPlayerColor: color.avocado,
  },
  {
    entityId: '503624',
    name: 'report',
    path: '/report',
    bgColor: color.seafoam,
    bgColorTrans: color.seafoam90,
    bgColorZero: color.seafoam0,
    menuBgColor: color.riptide,
    menuHighlightColor: color.shamrock,
    LogoGradientFrom: color.curiousBlue,
    LogoGradientTo: color.seafoam,
    highlightColor: color.teal,
    highlightTransColor: color.teal0,
    highlightBgColor: color.mint,
    highlightOppositeColor: color.seafoam,
    highlightArticle: color.teal,
    articleShareBg: color.seafoam,
    articleCorrectionBg: '#E4FFF3',
    articleCorrectionHighlight: '#34B8B5',
    transparentEffect: color.softteal50,
    sponsorTagColor: color.sunshineyellow,
    entityUuid: '1b9427f4-1aed-44f9-9bd3-d98c058ba7b9',
    depth: 1,
    speechifyPlayerColor: color.softteal,
  },
  {
    entityId: '503635',
    name: 'learn',
    path: '/learn',
    bgColor: color.lavender,
    bgColorTrans: color.lavender90,
    bgColorZero: color.lavender0,
    menuBgColor: color.softblue,
    menuHighlightColor: color.cornflowerBlue,
    LogoGradientFrom: color.dodgerBlue,
    LogoGradientTo: color.melrose,
    highlightColor: color.bluehouse,
    sponsorTagColor: color.mint,
    highlightTransColor: color.bluehouse0,
    highlightBgColor: color.mint,
    highlightOppositeColor: color.sunshineyellow,
    highlightArticle: color.navy,
    articleShareBg: color.lavender,
    articleCorrectionBg: '#EEF3FF',
    articleCorrectionHighlight: '#4563BC',
    transparentEffect: color.bluehouse50,
    entityUuid: '361f677a-4964-45b7-8e70-7a4271bd9ccb',
    depth: 1,
    speechifyPlayerColor: color.bluehouse,
  },
  {
    entityId: '518979',
    name: 'spark',
    path: '/spark',
    bgColor: color.lightPurple,
    bgColorTrans: color.lightPurple90,
    bgColorZero: color.lightPurple0,
    menuBgColor: color.mediumPurple,
    menuHighlightColor: color.mediumPurple,
    LogoGradientFrom: color.heliotropeapprox,
    LogoGradientTo: color.pinkLace,
    highlightColor: color.wisteriaPurple,
    sponsorTagColor: color.lightPurple,
    highlightTransColor: color.wisteriaPurple0,
    highlightBgColor: color.lightPurple,
    highlightOppositeColor: color.bilobaFlower,
    highlightArticle: color.darkPurple,
    articleShareBg: color.wisteriaPurple,
    articleCorrectionBg: '#F4EDFF',
    articleCorrectionHighlight: '#8667B7',
    transparentEffect: color.wisteriaPurple50,
    entityUuid: '4b44676e-f201-4230-97cc-8dab5c5ecd58',
    depth: 1,
    speechifyPlayerColor: color.wisteriaPurple,
  },
  // discover
  {
    entityId: '503649',
    name: 'news',
    path: '/discover/news',
    parent: 'd41a358a-51b2-406f-b9e7-aadb6fb2d13c',
    entityUuid: 'e044e5e9-9b6d-40e0-9b37-69c0c4e8d7f9',
    depth: 2,
  },
  {
    entityId: '503655',
    name: 'lifestyle',
    path: '/discover/lifestyle',
    parent: 'd41a358a-51b2-406f-b9e7-aadb6fb2d13c',
    entityUuid: 'a5024497-df28-43f9-a959-21e123366a56',
    depth: 2,
  },
  {
    entityId: '503660',
    name: 'entertainment',
    path: '/discover/entertainment',
    parent: 'd41a358a-51b2-406f-b9e7-aadb6fb2d13c',
    entityUuid: '773f34a3-311e-4dca-8e6f-cc57808b3908',
    depth: 2,
  },
  {
    entityId: '503666',
    name: 'advice',
    path: '/discover/advice',
    parent: 'd41a358a-51b2-406f-b9e7-aadb6fb2d13c',
    entityUuid: '9c4cc665-8815-4e51-93b1-cbe330072758',
    depth: 2,
  },
  {
    entityId: '503671',
    name: 'your voice',
    path: '/discover/your-voice',
    parent: 'd41a358a-51b2-406f-b9e7-aadb6fb2d13c',
    entityUuid: '7aa5dd76-ec82-42d0-9719-b8b9c0253632',
    depth: 2,
  },
  {
    entityId: 'video',
    name: 'video',
    path: VIDEO_PAGE,
    parent: 'd41a358a-51b2-406f-b9e7-aadb6fb2d13c',
    entityUuid: VIDEO_PAGE,
    depth: 2,
  },
  {
    entityId: 'scmp.com',
    name: 'scmp.com',
    path: SCMP_MAIN_SITE_DOMAIN,
    parent: 'd41a358a-51b2-406f-b9e7-aadb6fb2d13c',
    entityUuid: SCMP_MAIN_SITE_DOMAIN,
    depth: 2,
  },
  {
    entityId: 'scmplearn',
    name: 'Learn',
    path: 'https://www.scmplearn.com/',
    parent: 'd41a358a-51b2-406f-b9e7-aadb6fb2d13c',
    entityUuid: 'https://www.scmplearn.com/',
    depth: 2,
  },
  // discover
  // report
  {
    entityId: '503625',
    name: 'TEAM YP',
    path: '/report/team-yp',
    parent: '1b9427f4-1aed-44f9-9bd3-d98c058ba7b9',
    entityUuid: 'a83a46b6-6101-4706-a916-a709a2fae5b7',
    depth: 2,
  },
  {
    entityId: '503632',
    name: 'journalism resources',
    path: '/report/journalism-resources',
    parent: '1b9427f4-1aed-44f9-9bd3-d98c058ba7b9',
    entityUuid: '370f439b-672f-43f7-b71a-a565c27d5250',
    depth: 2,
  },
  {
    entityId: '503629',
    name: 'latest reports',
    path: '/report/latest-reports',
    parent: '1b9427f4-1aed-44f9-9bd3-d98c058ba7b9',
    entityUuid: '2cb14e17-b1cc-470d-80a1-77b1146016d3',
    depth: 2,
  },
  // report
  // learn
  {
    entityId: '503636',
    name: 'learning resources',
    path: '/learn/learning-resources',
    parent: '361f677a-4964-45b7-8e70-7a4271bd9ccb',
    entityUuid: '626dbea7-7c9d-4d22-86d9-5858703436f9',
    depth: 2,
  },
  {
    entityId: '503642',
    name: 'college & uni life',
    path: '/learn/college-uni-life',
    parent: '361f677a-4964-45b7-8e70-7a4271bd9ccb',
    entityUuid: '8de539e8-82cf-4164-8076-bf64a4e29a79',
    depth: 2,
  },
  {
    entityId: '503646',
    name: 'career advice',
    path: '/learn/career-advice',
    parent: '361f677a-4964-45b7-8e70-7a4271bd9ccb',
    entityUuid: '15fa62b3-f5f7-4d9a-b398-1c5d9d661e1e',
    depth: 2,
  },
  {
    entityId: '503647',
    name: 'events',
    path: '/learn/events',
    parent: '361f677a-4964-45b7-8e70-7a4271bd9ccb',
    entityUuid: 'd060fd82-7868-4290-a1a5-6701b062497b',
    depth: 2,
  },
  // spark
  {
    entityId: '518980',
    name: 'Stories',
    path: '/spark/stories',
    parent: '4b44676e-f201-4230-97cc-8dab5c5ecd58',
    entityUuid: '8bc5ac6c-f521-494f-b89f-ebb636cfb7d6',
    depth: 2,
  },
  {
    entityId: '518981',
    name: 'Worksheets',
    path: '/spark/worksheets',
    parent: '4b44676e-f201-4230-97cc-8dab5c5ecd58',
    entityUuid: '6c00005c-5d37-4a19-80b4-c0bde0171a61',
    depth: 2,
    showCarousel: false,
  },
  // news
  {
    entityId: '503650',
    name: 'Hong Kong',
    path: '/discover/news/hong-kong',
    parent: 'e044e5e9-9b6d-40e0-9b37-69c0c4e8d7f9',
    entityUuid: 'c52c177a-0c56-4834-8239-10d03ba32aca',
    depth: 3,
  },
  {
    entityId: '503651',
    name: 'Asia',
    path: '/discover/news/asia',
    parent: 'e044e5e9-9b6d-40e0-9b37-69c0c4e8d7f9',
    entityUuid: 'a5f3ee2d-4b51-40c2-a659-45b136f8389e',
    depth: 3,
  },
  {
    entityId: '503654',
    name: 'Environment',
    path: '/discover/news/environment',
    parent: 'e044e5e9-9b6d-40e0-9b37-69c0c4e8d7f9',
    entityUuid: 'ac013d55-9536-4229-ac47-ff7e7e25ad5f',
    depth: 3,
  },
  {
    entityId: '503652',
    name: 'Global',
    path: '/discover/news/global',
    parent: 'e044e5e9-9b6d-40e0-9b37-69c0c4e8d7f9',
    entityUuid: 'f3e93aa4-ffc4-476c-a045-cfa5206163d6',
    depth: 3,
  },
  {
    entityId: '503653',
    name: 'Sports',
    path: '/discover/news/sports',
    parent: 'e044e5e9-9b6d-40e0-9b37-69c0c4e8d7f9',
    entityUuid: 'f1cf81a9-234f-4431-abd7-d1975f8201ab',
    depth: 3,
  },
  // news

  // lifestyle
  {
    entityId: '503656',
    name: 'Features',
    path: '/discover/lifestyle/features',
    parent: 'a5024497-df28-43f9-a959-21e123366a56',
    entityUuid: 'bb7d70fe-6e1d-425a-8b30-a47db31429e9',
    depth: 3,
  },
  {
    entityId: '503657',
    name: 'Health & Fitness',
    path: '/discover/lifestyle/health-fitness',
    parent: 'a5024497-df28-43f9-a959-21e123366a56',
    entityUuid: 'ce47a5a0-de58-4a4b-874c-b275482962b6',
    depth: 3,
  },
  {
    entityId: '503658',
    name: 'Travel',
    path: '/discover/lifestyle/travel',
    parent: 'a5024497-df28-43f9-a959-21e123366a56',
    entityUuid: '6d773264-7650-4d7d-8fd3-1af6de3fdf6a',
    depth: 3,
  },
  {
    entityId: '503659',
    name: 'Food',
    path: '/discover/lifestyle/food',
    parent: 'a5024497-df28-43f9-a959-21e123366a56',
    entityUuid: 'b94dbd00-f6a9-4737-ac00-e9d9e0d6e4d7',
    depth: 3,
  },
  // lifestyle

  // entertainment
  {
    entityId: '503661',
    name: 'Movies',
    path: '/discover/entertainment/movies',
    parent: '773f34a3-311e-4dca-8e6f-cc57808b3908',
    entityUuid: '3f00e19d-aecc-452a-99c1-d5b928c2ac68',
    depth: 3,
  },
  {
    entityId: '503662',
    name: 'TV',
    path: '/discover/entertainment/tv',
    parent: '773f34a3-311e-4dca-8e6f-cc57808b3908',
    entityUuid: 'd905af08-af68-49c3-afa9-8c9a1fe91fc0',
    depth: 3,
  },
  {
    entityId: '503663',
    name: 'Tech & Gaming',
    path: '/discover/entertainment/tech-gaming',
    parent: '773f34a3-311e-4dca-8e6f-cc57808b3908',
    entityUuid: '95584617-9a63-4139-8738-112eb583e6b8',
    depth: 3,
  },
  {
    entityId: '503664',
    name: 'Music',
    path: '/discover/entertainment/music',
    parent: '773f34a3-311e-4dca-8e6f-cc57808b3908',
    entityUuid: '82b9040a-df75-4b60-ae21-056f5b59aac0',
    depth: 3,
  },
  {
    entityId: '503665',
    name: 'Books',
    path: '/discover/entertainment/books',
    parent: '773f34a3-311e-4dca-8e6f-cc57808b3908',
    entityUuid: 'afb468a7-8dd9-4f3e-887c-72167c254d9b',
    depth: 3,
  },
  // entertainment

  // advice
  {
    entityId: '503667',
    name: 'Personal development',
    path: '/discover/advice/personal-development',
    parent: '9c4cc665-8815-4e51-93b1-cbe330072758',
    entityUuid: '9ba1c8fb-13d4-4516-a5de-c5405a4e339a',
    depth: 3,
  },
  {
    entityId: '503668',
    name: 'Living',
    path: '/discover/advice/living',
    parent: '9c4cc665-8815-4e51-93b1-cbe330072758',
    entityUuid: '2e7d29f8-3573-4240-95a0-050c08dcfdcd',
    depth: 3,
  },
  {
    entityId: '503669',
    name: 'Money',
    path: '/discover/advice/money',
    parent: '9c4cc665-8815-4e51-93b1-cbe330072758',
    entityUuid: 'ba003ad1-025e-44f6-80be-d5cc1245a87a',
    depth: 3,
  },
  {
    entityId: '503670',
    name: 'Relationships',
    path: '/discover/advice/relationships',
    parent: '9c4cc665-8815-4e51-93b1-cbe330072758',
    entityUuid: '6fa345ab-3bc8-4aad-96dd-94d3bf45acf2',
    depth: 3,
  },
  // advice

  // your voice
  {
    entityId: '503672',
    name: 'Opinion',
    path: '/discover/your-voice/opinion',
    parent: '7aa5dd76-ec82-42d0-9719-b8b9c0253632',
    entityUuid: '34fcd250-c152-4da7-bef4-74d368aad3fc',
    depth: 3,
  },
  {
    entityId: '503673',
    name: 'Letters to the Editors',
    path: '/discover/your-voice/letters-editorial',
    parent: '7aa5dd76-ec82-42d0-9719-b8b9c0253632',
    entityUuid: 'fb557028-6c1e-4676-a98a-6f6e82081da7',
    depth: 3,
  },
  {
    entityId: '503674',
    name: 'Stories & Poems',
    path: '/discover/your-voice/stories-poems',
    parent: '7aa5dd76-ec82-42d0-9719-b8b9c0253632',
    entityUuid: 'e496057a-45be-4232-be67-ccd814fda9f3',
    depth: 3,
  },
  {
    entityId: '503675',
    name: 'Competitions',
    path: '/discover/your-voice/competitions',
    parent: '7aa5dd76-ec82-42d0-9719-b8b9c0253632',
    entityUuid: '80ac8112-fc9b-4869-8e45-347828066184',
    depth: 3,
  },
  // your voice

  // JUNIOR REPORTER PROGRAMME
  {
    entityId: '503626',
    name: 'About Junior Reporters',
    path: '/report/junior-reporters-club/article/3057140/about-junior-reporters-club',
    parent: 'a83a46b6-6101-4706-a916-a709a2fae5b7',
    entityUuid: 'c690a219-2799-440f-8928-5a37aacfe31e',
    depth: 3,
    render: '/_render/article',
  },
  {
    entityId: '503627',
    name: 'Register',
    path: 'https://docs.google.com/forms/d/e/1FAIpQLSew9KsmfUOnUAyy3mswOjPUpw-PXubpmrCP04dG_4bWke9R8Q/viewform',
    parent: 'a83a46b6-6101-4706-a916-a709a2fae5b7',
    entityUuid: 'b8c3f781-4f18-4271-9260-01893a660db2',
    depth: 3,
  },
  {
    entityId: '503628',
    name: 'Write for us guidelines',
    path: '/report/junior-reporters-club/article/3057140/about-junior-reporters-club#write',
    parent: 'a83a46b6-6101-4706-a916-a709a2fae5b7',
    entityUuid: '88f595ce-9a40-4f3c-850a-4f9ad30a7c3a',
    depth: 3,
    render: '/_render/article',
  },
  // JUNIOR REPORTER PROGRAMME

  // Journalism Resources
  {
    entityId: '503633',
    name: 'Media literacy',
    path: '/report/journalism-resources/media-literacy',
    parent: '370f439b-672f-43f7-b71a-a565c27d5250',
    entityUuid: '8f9ec420-b818-4ca8-abd7-cd5992ba4fa4',
    depth: 3,
  },
  {
    entityId: '503634',
    name: 'How to write an article',
    path: '/report/journalism-resources/how-write-article',
    parent: '370f439b-672f-43f7-b71a-a565c27d5250',
    entityUuid: '6f09af7a-156c-4f39-944c-81f2c40e8e54',
    depth: 3,
  },
  // Journalism Resources

  // Learning Resources
  {
    entityId: '503640',
    name: 'Listening',
    path: '/learn/learning-resources/listening',
    parent: '626dbea7-7c9d-4d22-86d9-5858703436f9',
    entityUuid: '23921df6-4538-406a-b9bb-3e57baf547f0',
    depth: 3,
  },
  {
    entityId: '518048',
    name: 'Worksheets',
    path: '/learn/learning-resources/worksheets',
    parent: '626dbea7-7c9d-4d22-86d9-5858703436f9',
    entityUuid: '0537dae5-580a-40c2-9dc1-6f931e6c788b',
    depth: 3,
  },
  {
    entityId: '503638',
    name: 'English exercises',
    path: '/learn/learning-resources/english-exercises',
    parent: '626dbea7-7c9d-4d22-86d9-5858703436f9',
    entityUuid: '8743f221-33c9-4833-a527-efb4d3756b27',
    depth: 3,
  },
  {
    entityId: '503637',
    name: 'Exam tips',
    path: '/learn/learning-resources/exam-tips',
    parent: '626dbea7-7c9d-4d22-86d9-5858703436f9',
    entityUuid: 'f31f6d63-d814-4cad-b273-8f51ef8a10f5',
    depth: 3,
  },
  {
    entityId: '503641',
    name: 'Debating tips',
    path: '/learn/learning-resources/debating-tips',
    parent: '626dbea7-7c9d-4d22-86d9-5858703436f9',
    entityUuid: 'c7c4e599-1f47-4545-8485-8b4f233e1c5b',
    depth: 3,
  },
  {
    entityId: '503639',
    name: 'From the experts',
    path: '/learn/learning-resources/experts',
    parent: '626dbea7-7c9d-4d22-86d9-5858703436f9',
    entityUuid: '5df5431a-243f-4643-8b1b-5b2ec69274c9',
    depth: 3,
  },
  // Learning Resources

  // College & Uni Life
  {
    entityId: '503644',
    name: 'Gap year',
    path: '/learn/college-uni-life/gap-year',
    parent: '8de539e8-82cf-4164-8076-bf64a4e29a79',
    entityUuid: '203edd0d-e3b1-468c-b03c-36e01a2b7b97',
    depth: 3,
  },
  {
    entityId: '503643',
    name: 'Student Life',
    path: '/learn/college-uni-life/student-life',
    parent: '8de539e8-82cf-4164-8076-bf64a4e29a79',
    entityUuid: '232b67e7-3740-48cf-abbc-85024cec004b',
    depth: 3,
  },
  {
    entityId: '503645',
    name: 'University Programmes',
    path: '/learn/college-uni-life/university-programmes',
    parent: '8de539e8-82cf-4164-8076-bf64a4e29a79',
    entityUuid: '1a25184c-df01-478c-9f3c-d01a1f818062',
    depth: 3,
  },
]

module.exports = {
  sections,
  sectionUuids,
}
