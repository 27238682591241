import { StyledFormContainer, StyledMessage } from './styles'

const Loading = () => {
  return (
    <StyledFormContainer>
      <StyledMessage>Loading...</StyledMessage>
    </StyledFormContainer>
  )
}

export default Loading
