import Button from '../Button'

import Icon from './icon-sent.svg'
import { StyledSubmitResultContainer, StyledTitle, StyledMessage } from './styles'

const ThankYou = ({ onDone }) => {
  return (
    <StyledSubmitResultContainer>
      <Icon />
      <StyledTitle>Thank you for your submission!</StyledTitle>
      <StyledMessage>
        For all submissions, keep an eye on our website and newspaper to see if your writing is published. For all applications, we will email you to notify you of your acceptance. We look forward to hearing from you again!
      </StyledMessage>
      <Button onClick={onDone} label="Done" className="black large" width="197" height="71" />
    </StyledSubmitResultContainer>
  )
}

export default ThankYou
