import {
  StyledError,
  StyledRadioInput,
  StyledRadioInputContainer,
  StyledRadioInputWrapper,
} from './styles'

const RadioInput = ({ question, register, errors }) => {
  return (
    <StyledRadioInputContainer>
      {question.options.map((option, index) => (
        <StyledRadioInputWrapper
          key={`${question.documentId}-${index}`}
          onClick={() => {
            const radio = document.getElementById(`${question.documentId}-${index}`)
            radio.click()
          }}
        >
          <StyledRadioInput
            id={`${question.documentId}-${index}`}
            type="radio"
            {...register(question.documentId)}
            value={option.value}
          />
          <label>{option.value}</label>
        </StyledRadioInputWrapper>
      ))}
      {errors[question.documentId] && (
        <StyledError>{errors[question.documentId].message}</StyledError>
      )}
    </StyledRadioInputContainer>
  )
}

export default RadioInput
