import CheckBoxInput from '../Fields/CheckBoxInput'
import RadioInput from '../Fields/RadioInput'
import TextArea from '../Fields/TextArea'

import { StyledQuestion } from './styles'

const Question = ({ question, register, errors, setError }) => {
  const renderAnswer = () => {
    switch (question.answerType) {
      case 'multiple_choice':
        return <CheckBoxInput question={question} register={register} errors={errors} />

      case 'radio_button':
        return <RadioInput question={question} register={register} errors={errors} />

      default:
        return (
          <TextArea
            placeholder="Write your answer here..."
            key={`${question.documentId}-textarea`}
            required={question.required}
            name={question.documentId}
            wordLimit={question.wordLimit}
            register={register}
            error={errors[question.documentId]}
            setError={setError}
          />
        )
    }
  }

  return (
    <>
      <StyledQuestion dangerouslySetInnerHTML={{ __html: question?.question?.html ?? '' }} />
      {renderAnswer()}
    </>
  )
}

export default Question
