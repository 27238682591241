import Button from '../Button'

import Icon from './icon-error.svg'
import { StyledSubmitResultContainer, StyledTitle, StyledMessage } from './styles'

const FormError = ({ onBack, errorType, messageWidth = '360' }) => {
  const { message } = (() => {
    switch (errorType) {
      case 'expired':
        return {
          message: 'This form is no longer accepting submissions.',
        }
      default:
        return {
          message: 'Please contact us at yp@scmp.com if you have questions.',
        }
    }
  })()

  return (
    <StyledSubmitResultContainer>
      <Icon />
      <StyledTitle>Sorry, something went wrong.</StyledTitle>
      <StyledMessage messageWidth={messageWidth}>{message}</StyledMessage>
      <Button onClick={onBack} label="Back" className="black large" width="197" height="71" />
    </StyledSubmitResultContainer>
  )
}

export default FormError
