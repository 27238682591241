import { format } from 'date-fns'
import { enUS } from 'date-fns/locale'
import { useState } from 'react'
import { Calendar } from 'react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

import FormLabel from './FormLabel'
import { StyledInput, StyledError, StyledCalendarWrapper } from './styles'

const DateInput = ({ label, placeholder, required, name, register, error, onChange, helperText }) => {
  const [showCalendar, setShowCalendar] = useState(false)
  const [selectedDate, setSelectedDate] = useState(null)

  const handleSelect = (date) => {
    setSelectedDate(date)
    setShowCalendar(false)
    onChange(format(date, 'yyyy-MM-dd'))
  }

  return (
    <div style={{ position: 'relative' }}>
      <FormLabel label={label} required={required} helperText={helperText} />

      <StyledInput
        value={selectedDate ? format(selectedDate, 'yyyy-MM-dd') : ''}
        onClick={() => setShowCalendar(true)}
        {...register(name)}
        readOnly
        error={error}
        placeholder={placeholder}
      />
      {error && <StyledError>{error.message}</StyledError>}

      {showCalendar && (
        <StyledCalendarWrapper>
          <Calendar
            date={selectedDate || new Date()}
            onChange={handleSelect}
            locale={enUS}
            color="#007bff"
            maxDate={new Date()}
          />
        </StyledCalendarWrapper>
      )}
    </div>
  )
}

export default DateInput
