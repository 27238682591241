import SvgTooltipArrow from 'public/assets/svg/icon-tooltip-arrow.svg'
import styled from 'styled-components'
import { fontFamily } from 'styles/mixins'

export const StyledLabel = styled.label`
  color: #666;
  font-family: ${fontFamily.roboto};
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.5px;
  display: block;
  margin: 8px 0;
  white-space: normal;
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
`

export const IconInfoContainer = styled.div`
  width: 16px;
  height: 16px;
  position: relative;
`

export const StyledTooltipsInfoArrow = styled(SvgTooltipArrow)`
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  top: -8px;
`

export const Tooltips = styled.div`
  box-sizing: border-box;
  position: absolute;
  background: #000000a6;
  padding: 12px 20px;
  border-radius: 4px;
  width: 100%;
  left: 0;
  white-space: normal;
  bottom: 24px;
`

export const TooltipsContent = styled.div`
  font-family: ${fontFamily.roboto};
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  color: #ffffff;
  width: inherit;
  height: inherit;
`

export const StyledInput = styled.input`
  border-radius: 4px;
  border: 2px solid ${(props) => (props.error ? '#FF8A8A' : '#bcbbbc')};
  background: ${(props) => (props.error ? '#ffe2e2' : '#f6f6f6')};
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  color: #000;
  font-family: ${fontFamily.gothic};
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  outline: none;
  &:focus {
    border: 2px solid ${(props) => (props.error ? '#FF8A8A' : '#34b8b5')};
  }
`

export const StyledFileDisplayInputWrapper = styled.div`
  position: relative;
`

export const StyledFileDisplayCancelButton = styled.button`
  position: absolute;
  right: 1rem;
  top: 1.3rem;
  cursor: pointer;
`

export const StyledSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const StyledSelect = styled.select`
  width: 100%;
  border-radius: 4px;
  border: 2px solid ${(props) => (props.error ? '#EB4254' : '#bcbbbc')};
  background: ${(props) => (props.error ? '#ffe2e2' : '#f6f6f6')};
  color: #000;
  font-family: ${fontFamily.gothic};
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 20px;
  box-sizing: border-box;
  outline: none;
`

export const StyledRadioInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const StyledRadioInputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  padding: 18px 20px 20px 20px;
  cursor: pointer;
  border-radius: 4px;
  border: 2px solid #bcbbbc;
  background: #f6f6f6;
  color: #000;
  font-family: ${fontFamily.gothic};
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  & label {
    cursor: pointer;
  }
`

export const StyledRadioInput = styled.input`
  flex-shrink: 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  cursor: pointer;
  border: 2px solid #ccc;
  border-radius: 50%;
  background: #fff;

  &:checked {
    border-color: #000;
    background-color: #000;
    box-shadow: inset 0 0 0 3px #f6f6f6;
  }
`

export const StyledCheckBoxInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const StyledCheckBoxInputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  padding: 18px 20px 20px 20px;
  cursor: pointer;
  border-radius: 4px;
  border: 2px solid #bcbbbc;
  background: #f6f6f6;
  color: #000;
  font-family: ${fontFamily.gothic};
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  & label {
    cursor: pointer;
  }
`

export const StyledCheckBoxInput = styled.input`
  flex-shrink: 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  margin-right: 8px;
  cursor: pointer;
  border: 2px solid #ccc;
  border-radius: 2px;

  &:checked {
    background-color: #000;
    border-color: #000;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 4L4.5 7.5L11 1' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
  }
`

export const StyledTextArea = styled.textarea`
  border-radius: 4px;
  border: 2px solid ${(props) => (props.error ? '#EB4254' : '#bcbbbc')};
  background: ${(props) => (props.error ? '#ffe2e2' : '#f6f6f6')};
  color: #000;
  font-family: ${fontFamily.gothic};
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  height: 300px;
  outline: none;
`

export const StyledCalendarWrapper = styled.div`
  position: absolute;
  top: -100px;
  z-index: 1000;
`

export const StyledError = styled.span`
  color: #eb4254;
  font-family: ${fontFamily.roboto};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 6px;
  display: inline-block;
`

export const StyledDropdownList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  list-style: none;
  padding: 0;
  margin: 0;
  border-radius: 4px;
  border: 2px solid #bcbbbc;
  max-height: 200px;
  overflow-y: auto;
  background: #f6f6f6;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`

export const StyledDropdownItem = styled.li`
  color: #000;
  font-family: ${fontFamily.gothic};
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 20px;
  cursor: pointer;
  &:hover {
    background: #e5e5e5;
  }
`

export const StyledLoadingItem = styled.li`
  padding: 8px;
`

export const StyledSchoolInputWrapper = styled.div`
  position: relative;
`

export const WordCount = styled.span`
  color: #666;
  text-align: right;
  font-family: ${fontFamily.roboto};
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.5px;
  color: ${(props) => (props.error ? '#EB4254' : '#666')};
  align-self: flex-end;
`
