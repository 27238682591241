import gql from 'graphql-tag'

export const CREATE_FORM_SUBMISSION = gql`
  mutation createSubmission($input: SubmissionInput!) {
    createSubmission(input: $input) {
      documentId
      success
      message
    }
  }
`
