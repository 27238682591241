import DateInput from '../Fields/DateInput'
import EducationInput from '../Fields/EducationInput'
import FileInput from '../Fields/FileInput'
import SchoolInput from '../Fields/SchoolInput'
import TextInput from '../Fields/TextInput'

const UserDetails = ({ userInfo, formData, register, errors, setValue, setError }) => {
  const {
    documentId: formId,
    email,
    name,
    dateOfBirth,
    school,
    phone,
    address,
    education,
    facebook,
    instagram,
    photo,
    artwork,
    educationOptions,
  } = formData

  const { useremail: userEmail, firstname: firstName, lastname: lastName } = userInfo

  return (
    <>
      {email?.display && (
        <TextInput
          label={email.title}
          required={email.required}
          helperText={email.helperText ?? ''}
          placeholder={email.placeholder ?? ''}
          name="email"
          defaultValue={userEmail ?? ''}
          register={register}
          error={errors.email}
          type="email"
        />
      )}

      {name?.display && (
        <TextInput
          label={name.title}
          required={name.required}
          helperText={name.helperText ?? ''}
          placeholder={name.placeholder ?? ''}
          name="name"
          defaultValue={`${firstName ?? ''} ${lastName ?? ''}`.trim()}
          register={register}
          error={errors.name}
        />
      )}

      {dateOfBirth?.display && (
        <DateInput
          label={dateOfBirth.title}
          required={dateOfBirth.required}
          helperText={dateOfBirth.helperText ?? ''}
          placeholder={dateOfBirth.placeholder ?? ''}
          name="dateOfBirth"
          register={register}
          error={errors.dateOfBirth}
          onChange={(date) => {
            register('dateOfBirth').onChange({
              target: {
                name: 'dateOfBirth',
                value: date,
              },
            })
          }}
        />
      )}

      {school?.display && (
        <SchoolInput
          label={school.title}
          required={school.required}
          helperText={school.helperText ?? ''}
          placeholder={school.placeholder ?? ''}
          register={register}
          error={errors.school}
        />
      )}

      {education?.display && (
        <EducationInput
          label={education.title}
          required={education.required}
          helperText={education.helperText ?? ''}
          placeholder={education.placeholder ?? ''}
          name="education"
          register={register}
          error={errors.education}
          educationOptions={educationOptions}
        />
      )}

      {phone?.display && (
        <TextInput
          label={phone.title}
          required={phone.required}
          helperText={phone.helperText ?? ''}
          placeholder={phone.placeholder ?? ''}
          name="phone"
          register={register}
          error={errors.phone}
        />
      )}

      {address?.display && (
        <TextInput
          label={address.title}
          required={address.required}
          helperText={address.helperText ?? ''}
          placeholder={address.placeholder ?? ''}
          name="address"
          register={register}
          error={errors.address}
        />
      )}

      {facebook?.display && (
        <TextInput
          label={facebook.title}
          required={facebook.required}
          helperText={facebook.helperText ?? ''}
          placeholder={facebook.placeholder ?? ''}
          name="facebook"
          register={register}
          error={errors.facebook}
        />
      )}

      {instagram?.display && (
        <TextInput
          label={instagram.title}
          required={instagram.required}
          helperText={instagram.helperText ?? ''}
          placeholder={instagram.placeholder ?? ''}
          name="instagram"
          register={register}
          error={errors.instagram}
        />
      )}

      {photo?.display && (
        <FileInput
          helperText={photo.helperText ?? ''}
          label={photo.title}
          required={photo.required}
          name="photo"
          register={register}
          error={errors.photo}
          accept="image/*"
          setValue={setValue}
          setError={setError}
          formId={formId}
        />
      )}

      {artwork?.display && (
        <FileInput
          helperText={artwork.helperText ?? ''}
          label={artwork.title}
          required={artwork.required}
          name="artwork"
          register={register}
          error={errors.artwork}
          accept="image/*"
          setValue={setValue}
          setError={setError}
          formId={formId}
        />
      )}
    </>
  )
}

export default UserDetails
