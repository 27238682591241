import { useFetchSchools } from 'hooks/hook-fetch-student-form'
import debounce from 'lodash/debounce'
import { useState, useCallback } from 'react'

import FormLabel from './FormLabel'
import {
  StyledInput,
  StyledError,
  StyledDropdownList,
  StyledDropdownItem,
  StyledLoadingItem,
  StyledSchoolInputWrapper,
} from './styles'

const SchoolInput = ({ register, label, placeholder, required, error, helperText }) => {
  const [searchText, setSearchText] = useState('')
  const [showDropdown, setShowDropdown] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [selectedSchool, setSelectedSchool] = useState(null)

  const { data, loading } = useFetchSchools({
    variables: {
      keyword: searchText,
    },
    skip: !searchText,
  })

  const debouncedSearch = useCallback(
    debounce((text) => {
      setSearchText(text)
    }, 500),
    [],
  )

  const schools = data?.getSchools || []

  return (
    <div>
      <FormLabel label={label} required={required} helperText={helperText} />
      <StyledSchoolInputWrapper>
        <StyledInput
          type="text"
          autoComplete="off"
          {...register('school.value')}
          onChange={(e) => {
            const newValue = e.target.value
            setInputValue(newValue)
            debouncedSearch(newValue)
            setShowDropdown(true)
            setSelectedSchool(null)
            register('school.documentId').onChange({
              target: {
                name: 'school.documentId',
                value: '',
              },
            })
          }}
          value={selectedSchool ? selectedSchool.value : inputValue}
          error={error}
          placeholder={placeholder}
        />
        {error && <StyledError>{error?.value?.message || error?.message}</StyledError>}

        {showDropdown && searchText && (
          <StyledDropdownList>
            {loading ? (
              <StyledLoadingItem>Loading...</StyledLoadingItem>
            ) : (
              <>
                {schools.map((school) => (
                  <StyledDropdownItem
                    key={school.documentId}
                    onClick={() => {
                      const schoolValue = {
                        value: school.name,
                        documentId: school.documentId,
                      }
                      setSelectedSchool(schoolValue)
                      register('school').onChange({
                        target: {
                          name: 'school',
                          value: schoolValue,
                        },
                      })
                      setInputValue(school.name)
                      setShowDropdown(false)
                    }}
                  >
                    {school.name}
                  </StyledDropdownItem>
                ))}
                <StyledDropdownItem
                  onClick={() => {
                    const schoolValue = {
                      value: inputValue,
                      documentId: '',
                    }
                    setSelectedSchool({ ...schoolValue, value: 'Other' })
                    register('school').onChange({
                      target: {
                        name: 'school',
                        value: schoolValue,
                      },
                    })
                    setShowDropdown(false)
                  }}
                >
                  Other
                </StyledDropdownItem>
              </>
            )}
          </StyledDropdownList>
        )}
        {selectedSchool?.documentId === '' && (
          <StyledInput
            type="text"
            defaultValue={inputValue}
            onChange={(e) => {
              const newValue = e.target.value
              register('school').onChange({
                target: {
                  name: 'school',
                  value: {
                    value: newValue,
                    documentId: '',
                  },
                },
              })
            }}
            placeholder="Enter your school name"
            style={{ marginTop: '10px' }}
            error={error}
            autoFocus
          />
        )}
      </StyledSchoolInputWrapper>
    </div>
  )
}

export default SchoolInput
