import styled from 'styled-components'
import { fontFamily } from 'styles/mixins'

export const StyledQuestion = styled.label`
  color: #666;
  font-family: ${fontFamily.roboto};
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.5px;
  display: block;
  margin: 0;

  img {
    max-width: 100%;
  }
`
