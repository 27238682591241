import { StyledButton, StyledButtonOuter } from './styles'

const Button = ({ type, label, width, height, className, onClick }) => {
  return (
    <StyledButtonOuter>
      <StyledButton
        type={type}
        width={width}
        height={height}
        className={className}
        onClickCallback={onClick}
      >
        {label}
      </StyledButton>
    </StyledButtonOuter>
  )
}

export default Button
