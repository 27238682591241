import {
  StyledError,
  StyledCheckBoxInput,
  StyledCheckBoxInputContainer,
  StyledCheckBoxInputWrapper,
} from './styles'

const CheckBoxInput = ({ question, register, errors }) => {
  const maxSelect = question.maxSelect

  const handleClick = (event) => {
    if (event.target.type === 'checkbox') {
      const checkedBoxes = document.querySelectorAll(
        `input[name="${question.documentId}"]:checked`,
      ).length
      if (maxSelect && checkedBoxes > maxSelect) {
        event.target.checked = false
        return
      }
      return
    }
  }

  const handleWrapperClick = (event, inputId) => {
    if (event.target.tagName !== 'INPUT') {
      const checkbox = document.getElementById(inputId)
      checkbox.click()
    }
  }

  return (
    <StyledCheckBoxInputContainer>
      {question.options.map((option, index) => (
        <StyledCheckBoxInputWrapper
          key={`${question.documentId}-${index}`}
          onClick={(e) => handleWrapperClick(e, `${question.documentId}-${index}`)}
        >
          <StyledCheckBoxInput
            id={`${question.documentId}-${index}`}
            type="checkbox"
            {...register(question.documentId)}
            value={option.value}
            onClick={handleClick}
          />
          <label>{option.value}</label>
        </StyledCheckBoxInputWrapper>
      ))}
      {errors[question.documentId] && (
        <StyledError>{errors[question.documentId].message}</StyledError>
      )}
    </StyledCheckBoxInputContainer>
  )
}

export default CheckBoxInput
